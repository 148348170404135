<template>
  <!-- 公司信息 -->
  <div class="outer-page">
    <div style="display: flex">
      <Card style="width: 1000px" title="公司信息">
        <Form :label-width="100" ref="form" :model="form" :rules="rules">
          <Row>
            <Col span="11">
              <FormItem label="企业名称: " prop="name">
                <Input
                  clearable
                  placeholder="请输入企业名称"
                  v-model="form.name"
                ></Input>
              </FormItem>
            </Col>
            <Col span="11" offset="1">
              <FormItem label="统一社会信用代码: " prop="creditCode">
                <Input
                  clearable
                  placeholder="请输入统一社会信用代码"
                  v-model="form.creditCode"
                ></Input>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span="11">
              <FormItem label="企业类型: " prop="enterpriseType">
                <Select
                  clearable
                  placeholder="请输入企业类型"
                  v-model="form.enterpriseType"
                >
                  <Option
                    v-for="(item, index) in companyData"
                    :key="index"
                    :value="item.id"
                    >{{ item.name }}</Option
                  >
                </Select>
              </FormItem>
            </Col>
            <Col span="11" offset="1">
              <!-- <FormItem label="种养一体: ">
                <Select v-model="form.isFarmPlant">
                  <Option
                    :value="item.value"
                    v-for="item in cityList"
                    :key="item.label"
                    >{{ item.label }}</Option
                  >
                </Select>
              </FormItem> -->
              <FormItem label="地址: " prop="address">
                <Input placeholder="请输入地址" v-model="form.address">
                  <span
                    slot="append"
                    class="iconfont"
                    style="cursor: pointer"
                    @click="mapModelShow"
                    >&#xe648;</span
                  >
                </Input>
              </FormItem>
            </Col>
          </Row>
          <!-- <Row>
            <Col span="11">
              <FormItem label="养殖场数量: " prop="farmCount">
                <Input
                  placeholder="请输入养殖数量"
                  v-model="form.farmCount"
                ></Input>
              </FormItem>
            </Col>
            <Col span="11" offset="1">
              <FormItem label="养殖品种: " prop="typeInfo">
                <Select
                  v-model="form.typeInfo"
                  multiple
                  placeholder="请选择养殖品种"
                >
                  <Option
                    v-for="(item, index) in catData"
                    :key="index"
                    :value="item.id + '/' + item.name"
                    >{{ item.name }}</Option
                  >
                </Select>
              </FormItem>
            </Col>
          </Row> -->
          <Row>
            <Col span="11">
              <FormItem label="联系人: " prop="linkName">
                <Input
                  clearable
                  placeholder="请输入联系人名称"
                  v-model="form.linkName"
                ></Input> </FormItem
            ></Col>
            <Col span="11" offset="1">
              <FormItem label="电话号码: " prop="tel">
                <Input
                  clearable
                  placeholder="请输入电话号码"
                  v-model="form.tel"
                ></Input>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span="11"> </Col>
            <Col span="11" offset="1"> </Col>
          </Row>
          <Row>
            <Col span="11">
              <FormItem label="省市区编码" prop="region">
                <Cascader
                  :data="areaList"
                  v-model="form.region"
                  :load-data="loadData"
                  change-on-select
                  placeholder="请选择区域"
                ></Cascader>
              </FormItem>
            </Col>
            <Col span="11" offset="1">
              <!-- <FormItem label="地址: " prop="address">
                <Input placeholder="请输入养殖地址" v-model="form.address">
                  <span
                    slot="append"
                    class="iconfont"
                    style="cursor: pointer"
                    @click="mapModelShow"
                    >&#xe648;</span
                  >
                </Input>
              </FormItem> -->
              <FormItem label="邮箱: " prop="email">
                <Input
                  clearable
                  placeholder="请输入邮箱"
                  v-model="form.email"
                ></Input>
              </FormItem>
            </Col>
          </Row>

          <Row>
            <Col span="23">
              <FormItem label="规模信息: ">
                <Input type="textarea" :rows="4"></Input>
              </FormItem>
            </Col>
          </Row>

          <Row>
            <Col span="23">
              <FormItem label="Logo: ">
                <upload-image :max="1" v-model="form.logo"> </upload-image>
              </FormItem>
            </Col>
          </Row>
        </Form>
        <div>
          <p slot="footer" style="text-align: center">
            <Button type="primary" @click="submit">修改</Button>
          </p>
        </div>
      </Card>

      <!-- 二维码 -->
      <!-- <Card style="flex: 1; margin-left: 30px">
        <div id="qrcode"></div>
      </Card> -->
    </div>

    <Modal v-model="mapModal" fullscreen>
      <search-map
        v-if="mapModal"
        :currentData="currentData"
        @back-location="dealMapData"
      ></search-map>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="() => (mapModal = false)">修改</Button>
        <Button @click="() => (mapModal = false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
import moment from "moment";
import until from "../../common/util";
import searchMap from "@/components/searchMap";
import QRCode from "qrcodejs2";
export default {
  name: "",
  components: {
    searchMap,
  },

  data() {
    return {
      config: {
        page: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
      },
      currentData: null,
      areaList: [],
      catData: [],
      cityList: [
        { value: "1", label: "是" },
        { value: "2", label: "否" },
      ],
      companyData: [],
      form: {},
      rules: {
        name: [{ required: true, message: "请输入姓名" }],
        birthday: [{ required: true, type: "date", message: "请选择出生年月" }],
        phone: [{ required: true, message: "请输入联系电话" }],
      },
      mapModal: false,
      modal: {
        title: "",
        show: false,
        submitLoading: false,
      },
    };
  },
  methods: {
    //提交数据
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        // this.form.typeInfo.forEach((item) => {
        //   params.type.push(item.split("/")[0]);
        //   params.typeName.push(item.split("/")[1]);
        // });
        if (this.form.region) {
          params.regionCode = this.form.region.join(",");
        }
        this.$post(this.$api.company.EDIT, params)
          .then(() => {
            this.$Message.success("修改成功！");
            this.getInfomation();
          })
          .catch((e) => {
            console.log(e);
          });
      });
    },

    //获取用户信息
    getInfomation() {
      this.$post(this.$api.FIRM_ADMINISTRATOR.LIST, {
        companyNo: localStorage.getItem("companyNo"),
      }).then((res) => {
        this.form = res;
        this.form.region = [Number(res.regionCode)];
        this.form.logo = this.getImgUrl(res.servicePath, res.logo);
      });
    },

    mapModelShow() {
      this.currentData = this.form.thirdLongLat;
      this.mapModal = true;
    },
    loadData(item, callback) {
      item.loading = true;
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: item.id,
      })
        .then((res) => {
          res.forEach((item) => {
            item.label = item.name;
            item.value = item.id;
            if (item.hasChild == "true") {
              item.loading = false;
              item.children = [];
            }
          });
          item.children = res;
          callback();
        })
        .finally(() => {
          item.loading = false;
        });
    },
    dealMapData(mapData) {
      this.form.farmerAddress = mapData.address;
      this.form.thirdLongLat = mapData.thirdLongLat;
    },
    //二维码
    qrcodeInit() {
      let qrcode = new QRCode(document.getElementById("qrcode"), {
        text: "http://jindo.dev.naver.com/collie",
        width: 128,
        height: 128,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    //企业类型
    getCompanyCategory() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: "203",
      }).then((res) => {
        this.companyData = res.list;
      });
    },
    //省市区列表接口
    getAreaList() {
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: "500153",
      }).then((res) => {
        res.forEach((item) => {
          item.label = item.name;
          item.value = item.id;
          item.loading = false;
          item.children = [{ label: "无", value: "", disabled: "ture" }];
        });
        this.areaList = res;
      });
    },
    //养殖品种
    getCategory() {
      this.$post(this.$api.PRODUCT_CATEGORY.LIST, {
        type: "2",
      }).then((res) => {
        this.catData = res.list;
      });
    },
  },
  mounted() {
    this.getInfomation();
    this.getCompanyCategory();
    this.getAreaList();
    this.qrcodeInit();
    this.getCategory();
  },
  watch: {},
};
</script>

<style lang="less" scoped>
</style>